import React from 'react';
import { useRef } from 'react';
import emailjs, { sendForm } from '@emailjs/browser';
import { motion } from 'framer-motion';

const Contact = () => {

  const [click, setClick] = React.useState(false);

  
  const form = useRef();
  
  const sendEmail = (e) => {
      e.preventDefault();
        emailjs
        .sendForm(
            'service_q94juzp', 
            'template_3eakdz8',
             form.current, 
             'LLfMdN8XFJfSFJc8c')
          .then(
            (result) => {
              console.log(result.text);
          },
           (error) => {
              console.log(error.text);
          });
      };
      const clickHandler = () => {
        setClick((prevClick) => !prevClick)

      }

/*     */
  return (
    <div className='contact-section' id='contact'>
          <h3 className='contact-header'>Kontaktní formulář</h3>
      <div className='contact-wrapper'>
    <form ref={form} onSubmit={sendEmail}>
      <label className='name-label'>Celé jméno</label>
      <input className='name-input' type="text" name="user_name" />
      <label className='email-label'>E-mailová adresa</label>
      <input className='email-input' type="email" name="user_email" />
      <label className='message-label'>Zpráva</label>
      <textarea className='message' name="message" />
      <input className='send' onClick={clickHandler} type="submit" value="Odeslat zprávu" />
    </form>
    {click > 0 ? <p className='email-info'
    >E-mail sent successfully!</p> : <p className='email-info'></p>}
    </div>
    </div>
  )
}

export default Contact