import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const About = () => {
  // Create a reference to the about section
  const aboutRef = useRef(null);
  // State to track whether the about section is visible
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // IntersectionObserver options
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin applied
      threshold: 0.5 // Trigger when 50% of the element is visible
    };

    // Create IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Set isVisible to true when about section enters viewport
          setIsVisible(true);
          // Stop observing once the element is visible
          observer.unobserve(entry.target);
        }
      });
    }, options);

    // Start observing the about section
    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    // Clean up: stop observing when component unmounts
    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []); // Run the effect only once on component mount

  return (
    <div className='about-section' ref={aboutRef}>
      {/* Animate the about header */}
      <motion.h2
        initial={{ x: -100, opacity: 0 }} // Initial position and opacity
        animate={isVisible ? { x: 0, opacity: 1 } : {}} // Animate if isVisible is true
        transition={{ type: 'spring', stiffness: 50 }} // Animation transition
        className='about-header'
      >
        O mně
      </motion.h2>
      {/* Animate the about paragraph */}
      <motion.p
        initial={{ x: -100, opacity: 0 }} // Initial position and opacity
        animate={isVisible ? { x: 0, opacity: 1 } : {}} // Animate if isVisible is true
        transition={{ type: 'spring', stiffness: 50, delay: 0.2 }} // Animation transition
        className='about-paragraph'
      >
        Jsem <span style={{ color: '#f046c8' }}>21 letý student</span> vysoké školy se zaměřením na <span style={{ color: '#f046c8' }}>aplikovanou informatiku</span>. Informatika je můj zájem také ve volném čase, kdy jsem například vytvořil tuto webovou aplikaci a jiné projekty, na které se můžete podívat na mém GitHub profilu. Pokud Vás zajímá více, můžete se podívat na můj LinkedIn profil.
      </motion.p>
    </div>
  );
}

export default About;
