import React from "react";
import Main from "./Components/Main";
import Contact from "./Components/Contact";
import About from "./Components/About";
import dataProjetky from "./dataProjekty";
import Project from "./Components/Project";
import Footer from "./Components/Footer"
function App() {

  const projects = dataProjetky.map((project) => {
    return (
      <Project
        key={project.id}
        project={project}
      />
    )
  })


  return (
    <div className="App">
      <Main />
      <About/>
      <div className="projects-wrapper">
      <h2 className="projects-header">Veřejné reference</h2>
        <div className="projects">
          {projects}
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;