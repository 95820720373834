import React from 'react';
import { motion } from 'framer-motion';

const Main = () => {

const [move, setMove] = React.useState(false);


  return (
    <div className='main-wrapper'
    id='about'>
        <motion.h1 
          className='about-me-header'
            initial={{x:0}}
            whileInView={{x:-110}}
            transition={{ delay: 0.3, type: "spring", stiffness: 65 }}
        >
          <motion.img 
            className='about-icon' 
            src='../imgs/user.svg'
            initial={{ opacity:0 }}
            whileInView={{ opacity:1 }}
            transition={{delay:0.8}}
        >
          </motion.img> Denis Vejrážka
        </motion.h1>

      <motion.p 
        className='about-me-info' 
        style={{textAlign: "left", marginLeft:"15px",paddingLeft: "10px"}}
        initial={{x:0, opacity:0}}
        whileInView={{x: 5, opacity:1}}
        transition={{ delay: 0.6, type: "spring", stiffness: 50 }}
        >Junior Vývojář
      </motion.p>

      <motion.p 
        style={{textAlign: "left",fontSize: "17px", fontWeight: "500", marginLeft: "10px",paddingLeft: "10px"}} 
        className='about-me-doing'
        initial={{x:0, opacity:0}}
        whileInView={{x: -10, opacity:1}}
        transition={{ delay: 0.9, type: "spring", stiffness: 50 }}
        >Věnuji se tvorbě webů a software pro menší firmy a podnikatele.
      </motion.p>
      
      <motion.p 
        className='skill-text'
        style={{textAlign: "left",fontSize: "17px", fontWeight: "500", paddingLeft: "10px", marginLeft:"10px"}}
        initial={{x:0, opacity:0}}
        whileInView={{x: -10, opacity:1}}
        transition={{ delay: 1, type: "spring", stiffness: 50 }}
        >Momentálně se věnuji technologii C# a Python...
      </motion.p>

      <a id="arrow-link" href="#contact"><motion.img className='arrow' src='../imgs/arrow-down.svg'
        animate={{y: [0,-10, 0]}}
        transition={{duration: 2, repeat: Infinity }}
      ></motion.img></a>
      <div className='main-icons'>
        <a href='https://github.com/denisvejrazka' target='_blank'><img className="gh" src='./imgs/icons8-github-2.svg'></img></a>
        <a href='https://www.linkedin.com/in/denis-vejrážka-048b69228/' target='_blank'><img className='li' src='./imgs/icons8-linkedin.svg'></img></a>
      </div>
    </div>
  )
}

export default Main

