import React from 'react';

const Footer = () => {

  const rok = new Date()
  let year = rok.getFullYear();

  return (
    <div className='footer'>
      <hr></hr>
        <p className='copyright'>{year} | Copyright © Denis Vejrážka<a 
        id='instagram-link' 
        href='https://www.instagram.com/denisvejrazka/?hl=cs' 
        target="_blank">
          <img 
            src='../imgs/instagram.svg' 
            id='instagram-icon'
            alt='instagram'
        ></img>
      </a></p>
    </div>
  )
}

export default Footer