import React from 'react';

const Project = (props) => {
  return (

    <div className='project-wrapper'>
      <div className='project-content'>
        <a href='https://barber-mnb-litomerice.cz/' target="_blank">
          <img id="project-img" src={`../imgs/${props.project.photo}`} />
        </a>
        <p className='project-paragraph'>V roce 2022 jsem vytvořil na míru tento web jako svůj první projekt, který se používá dodnes. Slouží jako prezentační web kadeřnictví, prostřednictvím kterého se mohou klienti objednat do salonu a vytvořit rezervaci. K vývoji tohoto webu jsem použil technologie jako <span style={{ color: '#f046c8' }}>HTML</span>, <span style={{ color: '#f046c8' }}>CSS</span> a <span style={{ color: '#f046c8' }}>React.js</span>. Další projekty najdete na mém GitHubu, jelikož toto je zatím můj jediný veřejný projekt.</p>
      </div>
    </div>
  );
};

export default Project;
